import React, { createContext, useState } from 'react';

export const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [settings, setSettings] = useState(null);
  const [statistics, setStatistics] = useState(null);

  const updateSettings = (newData) => {
    setSettings(newData);
  };

  const updateStatistics= (newData) => {
    setStatistics(newData);
  };

  return (
    <DataContext.Provider value={{ settings, statistics, updateSettings, updateStatistics }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;