import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './webapp/webpage/LoginPage';
import LessonPage from './webapp/webpage/LessonPage';
import StatisticsPage from './webapp/webpage/StatisticsPage';
import ProtectedRoute from './ProtectedRoute';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<LoginPage />} />
          <Route path="/lesson" element={<ProtectedRoute element={<LessonPage />} />} />
          <Route path="/statistics" element={<ProtectedRoute element={<StatisticsPage />} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;