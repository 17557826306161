import React from 'react';
import './StyledContainer.css'; // Import the CSS file

function StyledContainer({ children }) {
  return (
    <div className="styled-container">
      {children}
    </div>
  );
}

export default StyledContainer;