import React, { useState, useEffect, useRef } from 'react';

import './vocabularyCenter.css';  // Import the CSS file
import CardPanel from './CardPanel';

const TrainingPanel = ({wordsToShow, traningDone}) => {

    const [words, setWords] = useState(wordsToShow ?? []);
    const [wordStats, setWordStats] = useState(words);

    const roundsLeft = useRef(1);

    // Function to shuffle an array
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const allCardIsDone = () => {
        if(roundsLeft.current > 0){
            const shuffeldWords = shuffleArray(wordStats);
            setWords(shuffeldWords);
            roundsLeft.current -= 1;
        } else {
            traningDone();
        }
    };

    const cardSuccess = (id) => {
        setWordStats((words) =>
            words.map((item) => {
                if(item.id === id){
                    item.success += 1;
                }
                return item;
            })
          );
        
    };
    const cardFailure = (id) => {
        setWordStats((words) =>
            words.map((item) => {
                if(item.id === id){
                    item.tryCounter += 1;
                }
                return item;
            })
          );
    };
    

    return (
 
        <CardPanel
         cardSuccess={cardSuccess}
            cardFailure={cardFailure}
            noMoreCards={allCardIsDone}
            words={words}
        />
   
    );
};

export default TrainingPanel;