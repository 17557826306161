import React, { useState, useRef, useEffect } from 'react';

import './CardPanel.css';  // Import the CSS file

 

const CardPanel = ({cardSuccess, cardFailure, noMoreCards, words}) => {

    const [cards, setCards] = useState([]);
    const [backgroundColor, setBackgroundColor] = useState("rgba(255, 255, 255, 255)");
    const startX = useRef(null);
    const startY  = useRef(0);
    const deckOffset = 20;
    const [showHidden, setShowHidden] = useState(false);
    const isMobile = window.innerWidth < 768;
    
    const [animationFrameId, setAnimationFrameId] = useState(-1);

    // Define your animation cleanup function
    const cleanupAnimation = () => {
        // Animation cleanup code here
        cancelAnimationFrame(animationFrameId);
    };


    useEffect(() => {
        if(words !== undefined && words.length > 0){
            const updatedCards = words.map((card, index) => ({
                ...card,
                rotation: 0, // Generates a random number between -30 and 30
                posX: 0, // Generates a random number between -10 and 10
                posY: 0,
                scale: 1,
                startX: 0,
                startY: 0,
                startScale: 1,
                startRotation: 0,
                animateStartFrameTime: 0,
                animateState: "start",
                animationTime: 0.5,
                targetPosX: index===0 ? 0 : Math.random() * 10 - 5,
                targetPosY: Math.max(0, (index-1) * deckOffset ),
                targetRotation: index===0 ? 0 : Math.random() * 20 - 10,
                targetScale: 1
            }));
            if(updatedCards.length > 0){
                setCards(updatedCards);
            }
        }



    }, [words]); // Empty dependency array means this effect runs once on mount and cleanup on unmount

    useEffect(() => {
        setupAnimation();

        // Return the cleanup function
        return () => {
            cleanupAnimation();
        };
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  
    // Define your animation setup function
    const setupAnimation = () => {
        // Animation setup code here
        const animateFunc = (currentTime) => {
            
            // Interpolate value function
            // delta value is bettwen 0 and 1
            // 0 means value1
            // 1 means value2
            const interpolateValue = (value1, Value2, delta) => {
                const clampedDelta = Math.min(1, Math.max(0, delta));
                return value1 * (1 - delta) + Value2 * clampedDelta;
            };

            setCards( cards => cards.map((card, index) => {
                const updatedCard = {...card};
                if(updatedCard.animateState === "start"){
                    updatedCard.animateState = "animate";
                    updatedCard.animateStartFrameTime = currentTime;
                }
                if(updatedCard.animateState === "animate"){
                    const deltaTimeFromLastStateChange = (1.0/updatedCard.animationTime) * (currentTime - updatedCard.animateStartFrameTime)/1000;

                    updatedCard.posY = interpolateValue(updatedCard.startY, updatedCard.targetPosY, deltaTimeFromLastStateChange);
                    updatedCard.posX = interpolateValue(updatedCard.startX, updatedCard.targetPosX, deltaTimeFromLastStateChange);
                    updatedCard.rotation = interpolateValue(updatedCard.startRotation, updatedCard.targetRotation, deltaTimeFromLastStateChange);
                    //updatedCard.scale = interpolateValue(updatedCard.startScale, updatedCard.targetScale, deltaTimeFromLastStateChange);

                    if(deltaTimeFromLastStateChange >= 1){
                        updatedCard.animateState = "end";
                    }
                }
                return updatedCard;
            }));
            

            setAnimationFrameId( requestAnimationFrame(animateFunc) );
        };

        setAnimationFrameId( requestAnimationFrame(animateFunc) );
    };

    

    const handleTextToggle = () => {
        setShowHidden(true);
    };

    const handleTouchStart = (event) => {
        handleTextToggle();
        handleDragStart(event);
    }
    const handleDragStart = (event) => {
        startX.current = event.type === 'touchstart' ? event.touches[0].clientX : event.clientX;
        startY.current = event.type === 'touchstart' ? event.touches[0].clientY : event.clientY;
        console.log("Set Animate false");
       
        if(event.dataTransfer && typeof event.dataTransfer.setDragImage === 'function'){
            event.dataTransfer.setDragImage(new Image(), 0, 0);
        }
        
        
    };


    const getColorRed = (opacity) => {
        const red = 255;
        const green = 255 * (1-opacity);
        const blue = 255 * (1-opacity);
        return `rgba(${red}, ${green}, ${blue}, 255)`;
    }
    const getColorGreen = (opacity) => {
        const red = 255 * (1-opacity);
        const green = 255;
        const blue = 255 * (1-opacity);
        return `rgba(${red}, ${green}, ${blue}, 255)`;
    }

    const handleDrag = (event) => {
        const clientX = event.type === 'touchmove' ? event.touches[0].clientX : event.clientX;
        if (clientX === 0 && event.clientY === 0) {
            // This condition checks for the case where clientX and clientY are 0
            // which can occur in some browsers when the drag leaves the viewport
            return;
        }

        const clientY = event.type === 'touchmove' ? event.touches[0].clientY : event.clientY;
        if (clientX === 0 && event.clientY === 0) {
            // This condition checks for the case where clientX and clientY are 0
            // which can occur in some browsers when the drag leaves the viewport
            return;
        }


        const moveDirectionX = clientX - startX.current;
        const moveDirectionY = clientY - startY.current;
        const moveDistance = Math.abs(moveDirectionX);


        setCards((cards) => {
            // Create a new array to avoid direct mutation of the state
            const updatedCards = [...cards];
            // Update the value of the first item
            updatedCards[0].animateState = "manual";
            updatedCards[0].posX = moveDirectionX;
            updatedCards[0].posY = moveDirectionY * 0.75;
            updatedCards[0].rotation = moveDirectionX * 0.05;
            //updatedCards[0].scale = Math.max(1.0 - moveDistance / 600, 0.5);
            return updatedCards;
        });

        if(moveDistance > 50 ){
            const opacity = Math.max( 0, Math.min(1, (moveDistance - 50) / 250) );
            const color = moveDirectionX > 0 ? getColorGreen(opacity) : getColorRed(opacity);
            setBackgroundColor(color);
        }else{
            setBackgroundColor("rgba(255, 255, 255, 255)");
        }
    };

    const insertAtRandomPosition = (array, element) => {
        const randomIndex = Math.floor(1 + Math.random() * Math.min(9, array.length));
        array.splice(randomIndex, 0, element);
        return array;
      };

    const handleDragEnd = (event) => {
        console.log("Set animate True");

        if (cards.length === 0) return;

        const clientX = event.type === 'touchend' ? event.changedTouches[0].clientX : event.clientX;
        const moveDirection = clientX - startX.current;

        const updateCard = (card, index) => {
            const updatedCard = { ...card };
            updatedCard.animateState = "start";
            updatedCard.startX = updatedCard.posX;
            updatedCard.startY = updatedCard.posY;
            updatedCard.startScale = updatedCard.scale;
            updatedCard.startRotation = updatedCard.rotation;
            updatedCard.targetPosY = Math.max(0, (index-1) * deckOffset );
            updatedCard.targetPosX = index === 0 ? 0 : updatedCard.targetPosX;
            updatedCard.targetScale = 1;
            if(index === 2){
                updatedCard.targetRotation = updatedCard.targetRotation * 0.5;
            }
            updatedCard.targetRotation = index < 2 ? 0 : updatedCard.targetRotation;
            return updatedCard;
        }

        if( Math.abs(moveDirection) > 100){
            if (moveDirection < 0) {
                // Swiped right
                if(typeof cardFailure === 'function'){
                    cardFailure(cards[0].id, cards[0].lesson);
                }
                

                setCards((cards) => {
                    if (cards.length === 0) return cards;  // If no cards, return the same array

                    const firstCard = cards[0];
                    firstCard.targetPosX = Math.random() * 10 - 5;
                    firstCard.targetRotation = Math.random() * 20 - 10;
                    firstCard.targetScale = 1;
                    const restCards = cards.slice(1);
                    const updatedCards = insertAtRandomPosition(restCards, firstCard);//  [...restCards, firstCard];

                    // Update the remaining cards
                    return updatedCards.map((card, index) => {
                        const updatedCard = updateCard(card, index);
                        updatedCard.animationTime = index===0 ? 0.1 : 0.4;
                        return updatedCard;
                    });
                });
            } else {
                // Swiped left
                if(typeof cardSuccess === 'function'){
                    cardSuccess(cards[0].id, cards[0].lesson);
                }
                if(cards.length === 1){
                    noMoreCards();
                }
                setCards(cards => {
                    // Remove the first card
                    const updatedCards = cards.slice(1);
    
                    // Update the remaining cards
                    return updatedCards.map((card, index) => {
                        const updatedCard = updateCard(card, index);
                        updatedCard.animationTime = index===0 ? 0.1 : 0.4;
                        return updatedCard;
                    });
                });
            }
            setShowHidden(false);
        }else{
            setCards(cards => {
                // Remove the first card
                const updatedCards = cards;

                // Update the remaining cards
                return updatedCards.map((card, index) => {
                    const updatedCard = updateCard(card, index);
                    updatedCard.animationTime = 0.4;
                    return updatedCard;
                });
            });
        }
        
        setBackgroundColor("rgba(255, 255, 255, 255)");
    };

    const cardsToRender = cards.slice().reverse().slice(-10);
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%', overflow: 'hidden', backgroundColor: backgroundColor }}>
            
            { cardsToRender.map((card, index) => {
                const isLastCard = index === cardsToRender.length - 1;
                return (
                    <div className="CardPanel-panel"
                    draggable={isLastCard}
                    onDragStart={isLastCard ? handleDragStart : undefined}
                    onDrag={isLastCard ? handleDrag : undefined}
                    onDragEnd={isLastCard ? handleDragEnd : undefined}
                    onMouseDown={isLastCard ? handleTextToggle : undefined}
                    onTouchStart={isLastCard ? handleTouchStart : undefined}
                    onTouchMove={isLastCard ? handleDrag : undefined}
                    onTouchEnd={isLastCard ? handleDragEnd : undefined}
                        style={{
                            position: 'absolute', 
                            cursor: 'grab',
                            padding: '10px', // Ensure padding for better visibility
                            borderRadius: '5vh', // Optional style for rounded corners
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)'    , // Optional style for shadow
                            width: isMobile ? '35vh':'40vh', // Optional style for width
                            height: isMobile ? '35vh' : '60vh', // Optional style for width
                            transform: `translateX(${card.posX}px) translateY(${card.posY}px) rotate(${card.rotation}deg) scale(${card.scale})`,
                            backgroundImage: 'url(images/b1.png)'
                        }}
                    >

                    { card.lesson==="englishToHindi"? (
                        <div className="CardPanel-container">
                            <div className="CardPanel-text">
                                {card.english}
                            </div>

                            <div className="CardPanel-text">
                            { isLastCard && showHidden ? card.symbols : null}
                            </div>
                            
                            <div className="CardPanel-text">
                                {isLastCard && showHidden ? card.hindi : null}
                            </div>
                        </div>
                    ):(
                        <div className="CardPanel-container">
                            <div className="CardPanel-text">
                                {card.hindi}
                            </div>

                            <div className="CardPanel-text">
                                {card.symbols}
                            </div>
                            
                            <div className="CardPanel-text">
                                {isLastCard && showHidden ? card.english : null}
                            </div>
                        </div>
                    )}
                    
                </div>
                );
            })}    
        </div>    
    );

};

export default CardPanel;