import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAxXTlOMnYQApxIOY00VH6Nxnbcb5lE2xM",
    authDomain: "anders-test-projcet.firebaseapp.com",
    projectId: "anders-test-projcet",
    storageBucket: "anders-test-projcet.appspot.com",
    messagingSenderId: "1038432132128",
    appId: "1:1038432132128:web:c0eb235de080ee01f37045",
    measurementId: "G-N764E9RNEC"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
