import React, { useContext, useEffect } from 'react';
import './SettingsPanel.css'; // Import the CSS file
import { DataContext } from '../DataProvider';

function SettingsPanel({ onCheckboxChange, onSliderChange }) {

  const { settings } = useContext(DataContext);

  // Debugging: Log settings to see if they are correctly passed
  useEffect(() => {
    console.log('Settings:', settings);
  }, [settings]);

  if (!settings) {
    // If settings are not loaded, return a loader or null
    return <div>Loading settings...</div>;
  }


  return (
    <div className="settings-container">
      <form className="settings-form">
        <h1>Settings</h1>

        <div className="form-group">
          <label>English To Hindi</label>
          <input
            type="checkbox"
            name="englishToHindi"
            checked={settings.englishToHindi}
            onChange={onCheckboxChange}
          />
        </div>

        <div className="form-group">
          <label>Hindi To English</label>
          <input
            type="checkbox"
            name="hindiToEnglish"
            checked={settings.hindiToEnglish}
            onChange={onCheckboxChange}
          />
        </div>

        <div className="form-group">
          <label>Hindi Audio To English</label>
          <input
            type="checkbox"
            name="hindiAudioToEnglish"
            checked={settings.hindiAudioToEnglish}
            onChange={onCheckboxChange}
          />
        </div>

        <div className="form-group">
          <label>Number of New Words</label>
          <div className="slider-container">
            <input
              type="range"
              name="numberOfNewWords"
              min="1"
              max="50"
              value={settings.numberOfNewWords}
              onChange={onSliderChange}
            />
            <div className="slider-value">{settings.numberOfNewWords}</div>
          </div>
        </div>

        <div className="form-group">
          <label>Number of Words Per Training Session</label>
          <div className="slider-container">
            <input
              type="range"
              name="wordsPerTrainingSession"
              min="1"
              max="50"
              value={settings.wordsPerTrainingSession}
              onChange={onSliderChange}
            />
            <div className="slider-value">{settings.wordsPerTrainingSession}</div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SettingsPanel;