import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut, onAuthStateChanged  } from 'firebase/auth';
import { auth } from '../../../firebase';
import './NavBar.css';

const NavBar = () => {

  const isMobile = window.innerWidth < 768;
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error("Failed to logout: ", error);
    }
  };

  return (
    
    <div className="top-menu">
      {!isMobile && (
        <div className="left-side">
          <i className="icon-class"></i>
          <span>Word Training</span>
        </div>
      )}
      
      {user ? (
      <div className="middle">
        <Link to="/statistics">Home</Link>
        <Link to="/lesson">Lesson</Link>
      </div>
      ) : (
        <div className="middle"/>
      )}
      
      {user ? (
      <div className="right-side">
        <button onClick={handleLogout} className="logout-button">Logout</button>
      </div>
      ) : (
        <div className="right-side"/>
      )}
      
    </div>
  );
};

export default NavBar;