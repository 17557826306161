import React, { useState, useEffect, useContext } from 'react';
import './StandardStyle.css';  // Import the CSS file

import VocabularyPanel from '../components/Vocabulary/VocabularyPanel';
import TrainingPanel from '../components/Vocabulary/TrainingPanel';
import NavBar from '../components/navbar/NavBar';
import { DataContext } from '../components/DataProvider';


function LessonPage() {

  const [words, setWords] = useState([]);
  const [newWords, setNewWords] = useState([]);
  const { settings } = useContext(DataContext);

  useEffect(() => {
    fetch('https://hindi.andreasson.eu/api/words/new', {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
        // Initialize tryCounter to 0 for each word
        const wordsWithCounter = data.map(word => ({ ...word, tryCounter: 0, success: 0 }));
        setNewWords(wordsWithCounter);
    })
    .catch(error => console.error('Error fetching words:', error));
  }, []);

  useEffect(() => {
    // Fetch the words from API
    fetch('https://hindi.andreasson.eu/api/words', {
         method: 'GET',
         headers: {
             'Content-Type': 'application/json'
         }
     })
     .then(response => response.json())
     .then(data => {
         // Initialize tryCounter to 0 for each word
         const wordsWithCounter = data.map(word => ({ ...word, tryCounter: 0, success: 0 }));
         setWords(wordsWithCounter);
     })
     .catch(error => console.error('Error fetching words:', error));   
  }, []);


  const traningDone = () => {
    const combinedList = [
      ...newWords, ...words.filter(item => !newWords.some(obj => obj.id === item.id))
    ];
    setNewWords([]);
    setWords(combinedList);
  }

  return (
    <div className='default-background'>
        <NavBar/>

        { newWords.length > 0 && words.length < ((settings && settings.wordsPerTrainingSession) || 20) ? (
          
          <TrainingPanel 
            wordsToShow={newWords}
            traningDone={traningDone}
          />
        ) : (
          <VocabularyPanel 
            wordsToShow={words}
          />
        )}

    </div>
  );
}

export default LessonPage;