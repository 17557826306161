import React, { useContext, useEffect } from 'react';
import './SettingsPanel.css'; // Import the CSS file
import { DataContext } from '../DataProvider';

function StatisticsPanel() {

  const { statistics } = useContext(DataContext);

  // Debugging: Log settings to see if they are correctly passed
  useEffect(() => {
    console.log('statistics:', statistics);
  }, [statistics]);

  if (!statistics) {
    // If settings are not loaded, return a loader or null
    //return <div>Loading statistics...</div>;
  }


  return (
    <div className="settings-container">
      
        <h1>Settings</h1>
        <div>
            <label>totalWords: {statistics.totalWords} </label>
                
        </div>

        <div>
            <label>newWords: {statistics.newWords} </label>
  
        </div>

        <div>
            <label>newWords: {statistics.learningWords} </label>
  
        </div>

        <div>
            <label>learntWords: {statistics.learntWords} </label>
       
        </div>

    </div>
  );
}

export default StatisticsPanel;